import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
    return (
        <header className='header'>
            <h1 className='header-title'>Shawn Griffith</h1>
            <nav className='header-nav'>
                <ul>
                    <Link to='/'>Home</Link>
                    <Link to='/projects'>Projects</Link>
                    <Link to='/contact'>Contact</Link>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
