import React from 'react';
import './Home.css';

const Home = () => {
    return (
        <section className='home-page'>
            <h2>Portfolio</h2>
            <p>Hi, I'm Shawn and I'm a fun guy. I develop webbed sites and doesn't afraid of anything.</p>
        </section>
    );
};

export default Home;
