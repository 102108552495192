import React from 'react';
import './App.css';
import Header from './components/Header';
import HomePage from './pages/Home/Home';
import ContactPage from './pages/Contact/Contact';
import ProjectsPage from './pages/Projects/Projects';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const App = () => {
    return (
        <Router>
            <div className='App'>
                <Header />
                <Routes>
                    <Route path='/' element={<HomePage />} />
                    <Route path='/projects' element={<ProjectsPage />} />
                    <Route path='/contact' element={<ContactPage />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
