import React from 'react';
import './Projects.css';

const Projects = () => {
    return (
        <div className='projects-page'>
            <h2>Projects</h2>
            <ul>
                <li>
                    <a href='/pong/'>Pong Multiplayer Game</a>
                </li>
            </ul>
        </div>
    );
};

export default Projects;
